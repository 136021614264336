<template>
  <v-app>
    <v-main>
      <v-container align-center justify-center>
        <v-autocomplete
          v-model="selected"
          :items="options"
          dense
          filled
          label="בחר שם"
        ></v-autocomplete>
      </v-container>
      <v-container v-if="selected">
        <center>
            <v-row>
              <v-col>
                <b>לחץ להצגת הוראות הגעה:</b>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn elevation="4" class='gbutton' @click="linkTo"> </v-btn>
              </v-col>
              <v-col>
                <v-btn elevation="4" class='govmap-button' @click="linkToGovMap"> </v-btn>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col>
                <b>העתק קישור לשיתוף מסלול ב Google Maps:</b>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="copyHref" class="mx-2" fab dark small color="primary">
                  <v-icon dark>
                    mdi-clipboard
                  </v-icon>
                </v-btn><br/><br/>
                <v-text-field id="href-data" solo :value="href"/>
              </v-col>
            </v-row>
        </center>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  data: () => ({
      migrash: '',
      selected: '',
      GgovMapQuare: {revadim: '182509.7,631459.616',
                     dalia: '207157.7,721660.42'},
      GmapsStart: {revadim: '31.7723769,34.8133896',
                    dalia: '32.5925823,35.0802277'}
  }),
  created: () => {
      let kName = new URL(location.href).searchParams.get('name')
      if (!kName) {
        console.log('defaulting to revadim')
        kName = 'revadim'
      }
    document.title = `${document.title} - ${kName}`
  },
  computed: {
    name () {
      let kName = new URL(location.href).searchParams.get('name')
      if (kName) {
        return kName
      } else {
        console.log('defaulting to revadim')
        return 'revadim'
      }
    },
    data () {
      return require(`./json/${this.name}_data.json`)
    },
    href () {
      return `https://www.google.com/maps/dir/${this.GmapsStart[this.name]}/${this.selected}/@31.7730624,34.8111057,352m/data=!3m2!1e3!4b1!4m2!4m1!3e0`
    },
    govMapUrl () {
      return `https://www.govmap.gov.il/?c=${this.GgovMapQuare[this.name]}&z=8&q=${this.selected}`
    },
    options () {
      let arr = []
      this.data.forEach((val) => {
        arr.push({text: val["Name"], value: `${val['Lat']},${val['Lon']}`})
      })
      return arr
    }
  },
  methods: {
    linkTo () {
      window.open(this.href, "_blank")
    },
    linkToGovMap () {
      window.open(this.govMapUrl, "_blank")
    },
    copyHref() {
      let ele = document.getElementById("href-data");
      ele.focus()
      ele.select()
      document.execCommand('copy');
    }
  }
};


</script>
<style>
.gbutton {
  background-image: url('~@/assets/gmaps.png');
  height: 15em !important;
  width: 15em;
  background-position: center;
}
.govmap-button {
  background-image: url('~@/assets/govmapLogo.png');
  height: 15em !important;
  width: 15em;
  background-position: center;
}
</style>
